import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
/* reducers */
import User from '../Reducers/user';
import Notification from '../Reducers/notification';
import Checkout from '../Reducers/checkout';
import Dashboard from '../Reducers/dashboard';

const history = createBrowserHistory(); // no need to export since react-router used

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history });

const rootReducer = combineReducers({ router: routerReducer, User, Notification, Checkout, Dashboard });

const persistConfig = { key: 'root', storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware),
  devTools: process.env.NODE_ENV !== 'production', // thus disabled in production
});

export const persistor = persistStore(store);
export const reduxHistory = createReduxHistory(store); // for history to use in actions
