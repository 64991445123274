// user
export const INIT_SIGNIN = 'INIT_SIGNIN';
export const INIT_SIGNOUT = 'INIT_SIGNOUT';
export const TOGGLE_SITE_THEME = 'TOGGLE_SITE_THEME';
export const UPDATE_USER_LOCATION_DETAILS = 'UPDATE_USER_LOCATION_DETAILS';
export const UPDATE_CHARACTER_REMAINING_COUNT = 'UPDATE_CHARACTER_REMAINING_COUNT';
export const UPDATE_CHARACTER_TOTAL_COUNT = 'UPDATE_CHARACTER_TOTAL_COUNT';
export const UPDATE_TRIAL_STATUS = 'UPDATE_TRIAL_STATUS';

// notification
export const UPDATE_USAGE_THRESHOLD = 'UPDATE_USAGE_THRESHOLD';

// checkout
export const UPDATE_CHECKOUT_PLAN = 'UPDATE_CHECKOUT_PLAN';
export const UPDATE_PAID_DETAILS = 'UPDATE_PAID_DETAILS';

// dashboard
export const INIT_PRODUCT_DETAILS = 'INIT_PRODUCT_DETAILS';
export const CLEAR_PRODUCT_DETAILS = 'CLEAR_PRODUCT_DETAILS';
export const UPDATE_INTERVIEW_PREPARATION_DETAILS = 'UPDATE_INTERVIEW_PREPARATION_DETAILS';
