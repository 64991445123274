import {
  INIT_SIGNIN,
  INIT_SIGNOUT,
  TOGGLE_SITE_THEME,
  UPDATE_USER_LOCATION_DETAILS,
  UPDATE_CHARACTER_REMAINING_COUNT,
  UPDATE_TRIAL_STATUS,
  UPDATE_CHARACTER_TOTAL_COUNT,
  UPDATE_USAGE_THRESHOLD,
  UPDATE_CHECKOUT_PLAN,
  UPDATE_PAID_DETAILS,
  INIT_PRODUCT_DETAILS,
  CLEAR_PRODUCT_DETAILS,
  UPDATE_INTERVIEW_PREPARATION_DETAILS,
} from './actionTypes';

// user
export const InitSignin = (user) => ({
  type: INIT_SIGNIN,
  user,
});

export const InitSignOut = () => ({
  type: INIT_SIGNOUT,
});

export const toggleSiteTheme = (status) => ({
  type: TOGGLE_SITE_THEME,
  status,
});

export const updateUserLocationDetails = (locationDetails) => ({
  type: UPDATE_USER_LOCATION_DETAILS,
  locationDetails,
});

export const updateCharacterRemainingCount = (count) => ({
  type: UPDATE_CHARACTER_REMAINING_COUNT,
  count,
});

export const updateCharacterTotalCount = (count) => ({
  type: UPDATE_CHARACTER_TOTAL_COUNT,
  count,
});

export const updateTrialStatus = (status) => ({
  type: UPDATE_TRIAL_STATUS,
  status,
});

// notification
export const updateUsageThreshold = (status) => ({
  type: UPDATE_USAGE_THRESHOLD,
  status,
});

// checkout
export const updateCheckoutPlan = (details) => ({
  type: UPDATE_CHECKOUT_PLAN,
  details,
});

export const updatePaidDetails = (details) => ({
  type: UPDATE_PAID_DETAILS,
  details,
});

// dashboard
export const InitProductDetails = (details) => ({
  type: INIT_PRODUCT_DETAILS,
  details,
});

export const clearProductDetails = () => ({
  type: CLEAR_PRODUCT_DETAILS,
});

export const updateInterviewPreparationDetails = (details) => ({
  type: UPDATE_INTERVIEW_PREPARATION_DETAILS,
  details,
});
