import React, { useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { isAuthenticated } from '../../../Routes/ProtectedRoute';
import config from '../../../config';
import productsData from '../../../Data/productsData';
import { HeaderSecondaryLink, SideNavToggler } from '../Header/styles';
import {
  HomeSideMenuContainer,
  SideMenuOverlay,
  HomeSideMenuWrapper,
  SideNavHeader,
  SideNavLink,
  SideNavDiv,
  SideNavIcon,
  SideNavText,
  SideNavDescText,
} from './styles/HomeSideNav';
import SideNavAccordian from '../Header/styles/SideNavAccordians';
import SiteButton from '../FormComponents/SiteButton';
import { MdOutlineArrowOutward } from 'react-icons/md';
import { VscClose } from 'react-icons/vsc';

export default function HomeSideNav({ show, closeSideMenu, headerPricingLink }) {
  const [activeDropdown, setActiveDropdown] = useState(0);
  const dropdownToggle = (dropdownId) => {
    if (activeDropdown === -1) {
      setActiveDropdown(dropdownId);
    } else if (activeDropdown === dropdownId) {
      setActiveDropdown(-1);
    } else {
      setActiveDropdown(dropdownId);
    }
  };

  const signupLink = '/signup';
  return (
    <HomeSideMenuContainer show={show}>
      <SideMenuOverlay show={show} onClick={closeSideMenu} />
      <HomeSideMenuWrapper show={show}>
        <SideNavHeader>
          <SideNavToggler onClick={closeSideMenu}>
            <VscClose />
          </SideNavToggler>
        </SideNavHeader>
        <Accordion preExpanded={[0]} allowZeroExpanded={true} allowMultipleExpanded={false}>
          <SideNavAccordian
            groudId={0}
            groupName="Products"
            isexpanded={activeDropdown === 0 ? 'true' : 'false'}
            onClick={() => dropdownToggle(0)}
            onKeyDown={() => dropdownToggle(0)}
          >
            <div className="py-2">
              {productsData.map(({ productID, productIcon, productDisplayName, productPageLink, productDesc }) => (
                <SideNavDiv key={productID} href={productPageLink}>
                  <SideNavIcon src={config.cloudFront + productIcon} alt="" />
                  <div className="no-line-height">
                    <SideNavText>{productDisplayName}</SideNavText>
                    <SideNavDescText>{productDesc}</SideNavDescText>
                  </div>
                </SideNavDiv>
              ))}
            </div>
          </SideNavAccordian>
        </Accordion>

        <SideNavLink href={headerPricingLink}>
          <p className="me-2">Pricing</p> <MdOutlineArrowOutward className="site-fs-18" />
        </SideNavLink>
        <hr className="site-hr-line my-0" />

        {!isAuthenticated() ? (
          <div className="hideOnLargeMobile d-flex align-items-center justify-content-center px-2 mt-5">
            <HeaderSecondaryLink href="/signin">Sign in</HeaderSecondaryLink>
            <SiteButton type={'header-btn'} href={signupLink} style={{ marginLeft: '2rem' }}>
              Try for free
            </SiteButton>
          </div>
        ) : null}
      </HomeSideMenuWrapper>
    </HomeSideMenuContainer>
  );
}
