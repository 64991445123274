import { INIT_PRODUCT_DETAILS, CLEAR_PRODUCT_DETAILS, UPDATE_INTERVIEW_PREPARATION_DETAILS } from '../Actions/actionTypes';

const INIT_STATE = {
  dboardProductID: '',
  projectName: '',
  codingInterviewPreparation: {},
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT_DETAILS:
      return {
        ...state,
        ...action.details,
      };

    case UPDATE_INTERVIEW_PREPARATION_DETAILS:
      return {
        ...state,
        codingInterviewPreparation: action.details,
      };

    case CLEAR_PRODUCT_DETAILS:
      return { ...INIT_STATE };

    default:
      return state;
  }
};

export default Dashboard;
