import React, { Fragment, useState, useEffect } from 'react';
import { isAuthenticated } from '../../../Routes/ProtectedRoute';
import {
  HeaderContainer,
  HeaderOuter,
  HeaderInner,
  HeaderNavWrapper,
  HeaderNavItem,
  HeaderNavLink,
  HeaderSecondaryLink,
  SideNavToggler,
  HeaderLogoContainer,
  HeaderLogoWrapper,
  HeaderIconWrapper,
  HeaderLogoTextWrapper,
} from './styles/index';
import { SiteFormLogo, SiteHeaderIcon } from '../SiteIcon';
import HeaderDropdown from './HeaderDropdown';
import HomeSideNav from '../SideNav';
import SiteButton from '../FormComponents/SiteButton';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { IoSparklesSharp } from 'react-icons/io5';
import { BsRocketTakeoffFill } from 'react-icons/bs';

export default function Header({ pageType, activeBorder, customPricingLink }) {
  let headerPricingLink = customPricingLink ? customPricingLink : '/pricing';
  const [scrolling, setScrolling] = useState(false);
  const [mobileMenu, openMobileMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let top = window.scrollY <= 0;
    setScrolling(true);
    if (top === true) setScrolling(false);
  };

  const signupLink = '/signup';
  return (
    <Fragment>
      <HeaderContainer activeBorder={activeBorder} headerScrolling={scrolling} mobileMenu={mobileMenu}>
        <HeaderOuter>
          <HeaderInner headerScrolling={scrolling}>
            <div className="w-100 h-100 d-flex align-items-center justify-content-between">
              <div className="h-100 d-flex align-items-center">
                <HeaderLogoContainer>
                  <a className="no-outline" href={isAuthenticated() ? '/dashboard' : '/'}>
                    {/*<HeaderLogoWrapper><SiteFormLogo /></HeaderLogoWrapper>*/}
                    <HeaderLogoWrapper>
                      <HeaderIconWrapper>
                        <SiteHeaderIcon />
                      </HeaderIconWrapper>

                      <HeaderLogoTextWrapper>
                        <span className="d-block">Your learning,</span>
                        <span className="d-block">
                          your way{' '}
                          <span className="txt-dark-grey-color site-fs-13">
                            <IoSparklesSharp />
                            {/*<BsRocketTakeoffFill />*/}
                          </span>
                        </span>
                      </HeaderLogoTextWrapper>
                    </HeaderLogoWrapper>
                  </a>
                </HeaderLogoContainer>

                <HeaderNavWrapper>
                  <HeaderDropdown productsDropdown />
                  {/*<HeaderNavItem>Solutions</HeaderNavItem>*/}
                  <HeaderNavLink href={headerPricingLink}>Pricing</HeaderNavLink>
                </HeaderNavWrapper>
              </div>

              <div className="d-flex align-items-center">
                {!isAuthenticated() ? (
                  <div className="d-flex align-items-center">
                    <HeaderSecondaryLink href="/signin">Sign in</HeaderSecondaryLink>
                    <div className="d-none d-md-inline-block">
                      <SiteButton type={'header-btn'} href={signupLink} style={{ marginLeft: '1.5rem' }}>
                        Try for free
                      </SiteButton>
                    </div>
                  </div>
                ) : null}
                <div className="d-lg-none ms-3">
                  <SideNavToggler onClick={() => openMobileMenu(true)}>
                    <HiOutlineBars3 />
                  </SideNavToggler>
                </div>
              </div>
            </div>
          </HeaderInner>
        </HeaderOuter>
      </HeaderContainer>

      {/* mobile side navigation */}
      <HomeSideNav show={mobileMenu} closeSideMenu={() => openMobileMenu(false)} headerPricingLink={headerPricingLink} />
    </Fragment>
  );
}
