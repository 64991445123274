import React, { Fragment } from 'react';
import styled from 'styled-components';
import { HiArrowRight } from 'react-icons/hi';

export const RightArrow = styled(HiArrowRight)`
  position: relative;
  transition: var(--hoverTransitionDuration) transform ease-in-out;
  margin-left: 6px;
`;

const SiteBtn = styled.button.attrs({ className: 'siteBtn' })`
  border: none;
  outline: none;
  width: 100%;
  height: 45px;
  padding: 4px 8px;
  min-width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: var(--commonFontMedium);
  color: white;
  color: ${({ variant, theme }) => (variant === 'border' || theme === 'white') && 'var(--txtBrandColor)'};
  background: ${({ variant, theme }) => (variant === 'border' ? 'transparent' : theme === 'white' ? '#fff' : theme === 'black' ? 'var(--bgBrandColor)' : 'var(--bgBrandColor)')};
  border: ${({ variant }) => variant === 'border' && '2px solid var(--bgBrandColor)'};
  border-radius: var(--buttonRadius);
  transition: var(--hoverTransitionDuration) ease-in-out;
  &:hover {
    filter: ${({ theme, variant }) => (theme === 'white' ? 'brightness(97%)' : variant === 'border' ? 'initial' : 'brightness(95%)')};
    outline: none;
    color: ${({ variant }) => variant === 'border' && '#fff'};
    background: ${({ variant }) => variant === 'border' && 'var(--bgBrandColor)'};
  }
  &:hover ${RightArrow} {
    transform: translateX(4px);
  }
  &:active {
    filter: initial;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  @media (min-width: 360px) {
    font-size: 15px;
  }
`;

const SiteAnchorBtn = styled.a.attrs({ className: 'siteAnchorBtn' })`
  border: none;
  outline: none;
  padding: 4px 8px;
  height: 45px;
  min-width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: var(--commonFontMedium);
  color: white;
  color: ${({ variant, theme }) => (variant === 'border' || theme === 'white') && 'var(--txtBrandColor)'};
  background: ${({ variant, theme }) => (variant === 'border' ? 'transparent' : theme === 'white' ? '#fff' : theme === 'black' ? 'var(--bgBlackColor)' : 'var(--bgBrandColor)')};
  border: ${({ variant }) => variant === 'border' && '1px solid var(--borderColor)'};
  border-radius: var(--buttonRadius);
  transition: var(--hoverTransitionDuration) ease-in-out;
  &:hover {
    filter: ${({ theme, variant }) => (theme === 'white' ? 'brightness(97%)' : variant === 'border' ? 'initial' : 'brightness(95%)')};
    outline: none;
    border: ${({ variant }) => variant === 'border' && '1px solid var(--bgBrandColor)'};
  }
  &:hover ${RightArrow} {
    transform: translateX(4px);
  }
  &:active {
    filter: initial;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  @media (min-width: 360px) {
    font-size: 15px;
  }
`;

const ModalSecondaryBtn = styled(SiteBtn).attrs({ className: 'modalSecondaryBtn' })`
  color: var(--txtBrandColor);
  background: #fff;
  border: 1px solid var(--bgBrandColor);
  &:hover,
  &:active {
    filter: none;
    outline: none;
    background: var(--bgBluePowderColor);
  }
`;

const PrimaryMockTestBtn = styled(SiteBtn).attrs({ className: 'primaryMockTestBtn' })`
  height: 38px;
  width: 120px;
  padding: 4px;
  font-size: 14px !important;
  @media (min-width: 320px) {
    width: 130px;
  }
  @media (min-width: 360px) {
    width: 140px;
  }
  @media (min-width: 576px) {
    width: 150px;
    font-size: 15px !important;
  }
`;

const SecondaryMockTestBtn = styled(SiteBtn).attrs({ className: 'secondaryMockTestBtn' })`
  height: 35px;
  width: unset;
  min-width: 120px;
  padding: 4px 12px;
  font-size: 14px !important;
  font-family: var(--commonFontRegular);
  color: var(--bs-dark);
  background: #fff;
  border: 1px solid var(--borderColor);
  @media (min-width: 576px) {
    font-size: 15px !important;
  }
  @media (min-width: 768px) {
    height: 38px;
  }
  &:hover,
  &:active {
    filter: none;
    outline: none;
    color: #fff;
    background: var(--bgBrandColor);
    border-color: var(--bgBrandColor);
  }
`;

const BannerBtn = styled(SiteAnchorBtn).attrs({ className: 'bannerBtn' })`
  width: 100%;
  max-width: 320px;
  height: 54px;
  font-size: 16px !important;
`;

const FeatureBtn = styled(SiteAnchorBtn).attrs({ className: 'featureBtn' })`
  width: 100%;
  max-width: 220px;
  height: 48px;
  font-size: 15px !important;
  @media (min-width: 360px) {
    font-size: 16px !important;
  }
`;

const HeaderBtn = styled(SiteAnchorBtn).attrs({ className: 'headerBtn' })`
  height: 40px;
  min-width: 130px;
  @media (min-width: 992px) {
    min-width: 140px;
  }
`;

const FormBtn = styled(SiteBtn).attrs({ className: 'formBtn' })`
  width: 100%;
  height: 50px;
`;

const PricingBtn = styled(FormBtn).attrs({ className: 'pricingBtn' })`
  height: 45px;
`;

const DboardBtn = styled(SiteBtn).attrs({ className: 'dboardBtn' })`
  height: 45px;
  max-width: 200px;
`;

const BtnLoadingCircle = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid var(--bs-light);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @media (min-width: 360px) {
    width: 16px;
    height: 16px;
  }
`;

export default function SiteButton({ style, children, id, ref, href, onClick, active, disabled, loadingText, btnArrow, type, variant, theme }) {
  return (
    <Fragment>
      {type === 'banner-btn' && (
        <BannerBtn id={id} href={href} style={style} variant={variant} theme={theme}>
          <span>{children}</span>
          <RightArrow />
        </BannerBtn>
      )}

      {type === 'feature-btn' && (
        <FeatureBtn id={id} href={href} style={style} variant={variant} theme={theme}>
          <span>{children}</span> {btnArrow === 'true' && <RightArrow />}
        </FeatureBtn>
      )}

      {type === 'header-btn' && (
        <HeaderBtn id={id} href={href} style={style} variant={variant} theme={theme}>
          <span>{children}</span>
          <RightArrow />
        </HeaderBtn>
      )}

      {type === 'form-btn' && (
        <FormBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} variant={variant} theme={theme}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span> {btnArrow === 'true' && <RightArrow />}
            </Fragment>
          )}
        </FormBtn>
      )}

      {type === 'pricing-btn' && (
        <PricingBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} variant={variant} theme={theme}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span> <RightArrow />
            </Fragment>
          )}
        </PricingBtn>
      )}

      {type === 'modal-secondary-btn' && (
        <ModalSecondaryBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} theme={theme}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span>
            </Fragment>
          )}
        </ModalSecondaryBtn>
      )}

      {type === 'mock-test-btn' && (
        <PrimaryMockTestBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} theme={theme}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span>
            </Fragment>
          )}
        </PrimaryMockTestBtn>
      )}

      {type === 'secondary-mock-test-btn' && (
        <SecondaryMockTestBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} theme={theme}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span>
            </Fragment>
          )}
        </SecondaryMockTestBtn>
      )}

      {type === 'dboard-btn' && (
        <DboardBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} ref={ref} variant={variant} theme={theme}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span>
            </Fragment>
          )}
        </DboardBtn>
      )}

      {type === 'common-btn' && (
        <SiteBtn id={id} onClick={onClick} style={style} disabled={disabled} variant={variant} theme={theme}>
          <span>{children}</span>
        </SiteBtn>
      )}
    </Fragment>
  );
}
