import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LoadableHoc from './loadableHOC';
import ProtectedRoute, { isAuthenticated } from './ProtectedRoute';
import { ThemeProvider } from 'styled-components';
import { lightThemes, darkThemes } from '../Data/themeSheet';
import Home from '../Components/Home';

const Signup = LoadableHoc('Signup');
const Signin = LoadableHoc('Signin');
const ForgotPassword = LoadableHoc('Signin/ForgotPassword');
const Pricing = LoadableHoc('Pricing');
const Contact = LoadableHoc('Contact');
const Dashboard = LoadableHoc('Dashboard');
const Workspace = LoadableHoc('Dashboard/Workspace');

// policies
const PrivacyPolicy = LoadableHoc('Policies/PrivacyPolicy');
const RefundPolicy = LoadableHoc('Policies/RefundPolicy');
const FairUsagePolicy = LoadableHoc('Policies/FairUsagePolicy');
const Terms = LoadableHoc('Policies/Terms');

// product pages
const SuperLearner = LoadableHoc('Packages/SuperLearner');
const QuestGenerationAI = LoadableHoc('Packages/QuestGenerationAI');
const MockTestAI = LoadableHoc('Packages/MockTestAI');
const CodingInterviewPrepation = LoadableHoc('Packages/CodingInterviewPrepation');

// blogs
// const Blogs = LoadableHoc('Blogs');
const EducationWithAI = LoadableHoc('Blogs/EducationWithAI');

const Checkout = LoadableHoc('Checkout');
const CheckoutReturn = LoadableHoc('Checkout/CheckoutReturn');
const PaymentSuccessPage = LoadableHoc('Checkout/PaymentSuccessPage');
// const ErrorPage = LoadableHoc('UI/Error');

class RoutesWrapper extends Component {
  render() {
    const { darkTheme } = this.props;

    return (
      <ThemeProvider theme={darkTheme ? darkThemes : lightThemes}>
        {/* below jsx element is used to wrap mostly all site elements inside it */}
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/signin" component={Signin} />
            {/*<Route exact path="/forgot-password" component={ForgotPassword} />*/}
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/contact" component={Contact} />

            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/dashboard/:routeID" component={Dashboard} />
            {/*<ProtectedRoute exact path="/workspace/:routeID/:projectID" component={Workspace} />
            <ProtectedRoute exact path="/workspace/:routeID" component={Workspace}>
              <Redirect to="/dashboard" />
            </ProtectedRoute>*/}

            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/refund-policy" component={RefundPolicy} />
            <Route exact path="/fair-usage-policy" component={FairUsagePolicy} />
            <Route exact path="/terms" component={Terms} />

            {/*<Route exact path="/superlearner" component={SuperLearner} />*/}
            <Route exact path="/ai-question-generator" component={QuestGenerationAI} />
            <Route exact path="/ai-mock-test-platform" component={MockTestAI} />
            <Route exact path="/coding-interview-preparation-with-ai" component={CodingInterviewPrepation} />

            <Route exact path="/blog">
              <Redirect to="/blog/ai-in-education" />
            </Route>
            <Route exact path="/blog/ai-in-education" component={EducationWithAI} />

            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/return" component={CheckoutReturn} />
            <Route exact path="/payment-success" component={PaymentSuccessPage} />
            {/*<Route exact path="/error" component={ErrorPage} />*/}

            {isAuthenticated() ? <ProtectedRoute component={Dashboard} /> : <Route component={Home} />}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ User }) => ({
  darkTheme: User.darkTheme,
});

export default connect(mapStateToProps, null)(RoutesWrapper);
