import React, { useEffect } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import config from '../../config';
import SparkleSvg from '../../Animations/gradient-sparkle.lottie';
import homeBannerSvg from '../../Animations/homepage/homepage-banner-24-jan-2025-08-04.lottie';
import '../UI/Banner/styles/index.css';
import {
  HomeBannerOuter,
  HomeBannerInner,
  HomePrimaryContent,
  HomeLogo,
  HomeHeading,
  HighlightedHeadText,
  HighlightedHeadIcon,
  BannerDescriptionText,
  ButtonContainer,
  HomeSecondaryContent,
  PackagesVideoWrapper,
  HomeBannerLogosWrapper,
} from '../UI/Banner/styles';
import { SiteSparkleIcon } from '../UI/SiteIcon';
import SiteButton from '../UI/FormComponents/SiteButton';

export default function Banner({ signupLink }) {
  useEffect(() => {
    const txts = document.querySelector('#home-bnr-flip-txt-wrapper').children,
      txtsLen = txts.length;
    let index = 0;
    const textInTimer = 2800,
      textOutTimer = 2600;
    function animateText() {
      for (let i = 0; i < txtsLen; i++) {
        txts[i].classList.remove('home-banner-text-in', 'home-banner-text-out');
      }
      txts[index].classList.add('home-banner-text-in');
      setTimeout(function () {
        txts[index].classList.add('home-banner-text-out');
      }, textOutTimer);
      setTimeout(function () {
        if (index === txtsLen - 1) {
          index = 0;
        } else {
          index++;
        }
        animateText();
      }, textInTimer);
    }
    animateText();
  }, []);

  return (
    <HomeBannerOuter>
      <HomeBannerInner>
        <HomePrimaryContent>
          <HomeHeading>
            <span id="home-bnr-flip-txt-wrapper">
              <span className="home-bnr-flip-txt">
                <span className="home-title-xsm-inline-block">Crack</span> <span className="home-title-xsm-inline-block">Coding</span>{' '}
                <span className="d-block d-sm-inline-block">Interviews</span>
              </span>
              <span className="home-bnr-flip-txt">
                <span className="home-title-xsm-inline-block">Ace</span> <span className="home-title-xsm-inline-block">Aptitude</span>{' '}
                <span className="d-block d-sm-inline-block">Tests</span>
              </span>
              <span className="home-bnr-flip-txt">
                <span className="home-title-xsm-inline-block">Master</span> <span className="home-title-xsm-inline-block">Mock</span>{' '}
                <span className="d-block d-sm-inline-block">Tests</span>
              </span>
              <span className="home-bnr-flip-txt">
                <span className="home-title-xsm-inline-block">Conquer</span> <span className="home-title-xsm-inline-block">Competitive</span>{' '}
                <span className="d-block d-sm-inline-block">Exams</span>
              </span>
            </span>{' '}
            <span className="d-inline-block">
              with <HighlightedHeadText>Adaptive AI</HighlightedHeadText>
              {/*<HighlightedHeadIcon><DotLottieReact className="d-flex overflow-hidden loader-canvas-wrapper" src={SparkleSvg} loop autoplay useFrameInterpolation={false} /></HighlightedHeadIcon>*/}
            </span>
          </HomeHeading>
          <BannerDescriptionText>
            {/*Leyaa helps you evaluate your skills, unlock personalized learning paths, clear doubts instantly, and sharpen skills with tailored tests and mock interviews.*/}
            Leyaa helps you evaluate your skills, unlock AI-powered insights, and master challenging areas effectively with instant doubt clarification, tailored tests, and mock
            interviews.
          </BannerDescriptionText>
          <ButtonContainer>
            <SiteButton type={'banner-btn'} href={signupLink}>
              Get started for free
            </SiteButton>
          </ButtonContainer>
        </HomePrimaryContent>

        <HomeSecondaryContent>
          <DotLottieReact
            className="d-flex banner-card-radius elevation-shadow-2 overflow-hidden home-banner-canvas-wrapper"
            src={homeBannerSvg}
            loop
            autoplay
            useFrameInterpolation={false}
          />
        </HomeSecondaryContent>

        <HomeBannerLogosWrapper>
          <img className="img-fluid" src={config.cloudFront + '/Assets/Images/companies-logo-grey.svg'} alt="" />
        </HomeBannerLogosWrapper>
      </HomeBannerInner>
    </HomeBannerOuter>
  );
}
